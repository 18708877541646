.home-block{
    position: relative;
}
.home-rows {
    display: flex;
    width: 100%;
    margin: 20px 0;
}
.home-auditorium span , .home-helpdesk span , .home-exhibition span {
    background: #000;
    color: #fff;
    padding: 10px;
    border-radius: 20px 0;
}
#vid-skipbutton {
    position: absolute;
    right: 0;
    border-radius: 20px;
    padding: 15px;
    text-align: center;
    z-index: 999999;
  }