.col-12.registerBlock2 ,.col-12.registerBlock2 .login-view{
    padding: 30px;
  }
.registerBlock2{
    position: relative;
}

  .registerBlock2 .login-view .leftSide {
    border-radius: 30px 0px 0px 30px;
    position: relative;
    box-shadow: -4px -1px 14px #fff;
  }

  .registerBlock2 .login-view .rightSide {
    border-radius: 0px 30px 30px 0px;
    background: #fff;
    border: 1px solid #000;    
  }
 .registerBlock2 .rightBlock{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}
.registerBlock2 .rightBlock .centerBlock{
    object-fit: contain;
}
.registerBlock2 .profileimg {
  padding-top: 35px !important;
}
footer {
  background: #454545;
  color: #fff;
  padding: 10px;
  text-transform: capitalize;
}
#verifyOTP {
  border-radius: 20px;
  height: 45px;
}
.regtags .dropdown-container {
  border-radius: 30px !important;
}