.Mobileloginblock1 .imgLogo.img-responsive {
    width: 200px;
  }
  .Mobileloginblock1 .form-control{
    border-radius: 21px;
    background: transparent;
    color: #fff;
}
.Mobileloginblock1  .form-control:focus{
    border-color: #F37C23;
    box-shadow: 0 0 0 1px #F37C23;
}
.Mobileloginblock1 .form-label {
  color: #F37C23 ;
  font-size: 14px;
}
 .btn-lg.btnOrange.btn.btn-primary{
    border-radius: 30px !important;
    padding: 10px 50px !important;
  }
  .Mobileloginblock1 .footer{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}
footer {
  background: #454545;
  color: #fff;
  padding: 10px;
  text-transform: capitalize;
}
.Mobileloginblock1  #example-form .mt-3 input::-webkit-outer-spin-button,
.Mobileloginblock1 #example-form .mt-3 input::-webkit-inner-spin-button,#example-form .mt-2 input::-webkit-outer-spin-button,
.Mobileloginblock1 #example-form .mt-2 input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.Mobileloginblock1  #example-form .mt-3 input[type="number"], #example-form .mt-2 input[type="number"] {
  -moz-appearance: textfield;
}