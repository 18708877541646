.col-12.login-block2 ,.col-12.login-block2 .login-view{
    padding: 30px;
  }
.login-block2{
    position: relative;
}

  .login-block2 .login-view .leftSide {
    border-radius: 30px 0px 0px 30px;
    position: relative;
    box-shadow: -4px -1px 14px #fff;
  }

  .login-block2 .login-view .rightSide {
    border-radius: 0px 30px 30px 0px;
    background: #fff;
    border: 1px solid #000;    
  }

  .login-form-block2 .form-control{
      border-radius: 20px;
  }
  .login-form-block2 .form-control:focus{
      border-color: #2A357C;
      box-shadow: 0 0 0 1px #2A357C;
  }
  .login-form-block2 .form-label {
    color: #2A357C ;
  }
  .btn-lg.btnBlue.loginBtn.btn.btn-primary, .loginBtn{
    border-radius: 30px !important;
    padding: 10px 50px !important;
  }
  .rightBlock{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}
.rightBlock img{
    object-fit: contain;
}
.login-block2 .footer{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}
.login-block2  .col-12.row.login-view {
  max-width: 1280px;
  margin: 0 auto !important;
}
  .loginRTiltle h2 {
    background: firebrick;
    width: fit-content;
    padding: 10px;
    font-weight: bold;
    text-transform: full-width;
    color: #fff;
    text-shadow: 2px 1px 7px #000;
    margin-bottom: 30px;
  }
  .loginRTiltle h1 {
    background: firebrick;
    width: fit-content;
    padding: 10px;
    margin-left: 30px;
    font-weight: bold;
    text-transform: full-width;
    text-shadow: 2px 1px 7px #000;
  }
  .loginRbottom.py-4{
    position: absolute;
    bottom: 0;
    right: 13px;
  }
  .loginRbottom h3 {
    font-weight: bold;
    text-shadow: 0px 0px 3px black;
  }
  footer {
    background: #454545;
    color: #fff;
    padding: 10px;
    text-transform: capitalize;
  }
 .login-block2  #example-form .mt-4 input::-webkit-outer-spin-button,
 .login-block2  #example-form .mt-4 input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
 .login-block2  #example-form .mt-4 input[type="number"] {
    -moz-appearance: textfield;
  }