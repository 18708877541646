@import url(//fonts.googleapis.com/css?family=Lato:300:400);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
    font-family: "PlusJakartaSans";
    src: url('https://bharatexpo.com/PlusJakartaSans.ttf');
}

body {
    background: #f7f7f7 !important;
    color: #000;
    font-family: 'PlusJakartaSans' !important;
}

.statusTxtSUCCESS, .statusTxtSuccess {
    color: #01dc69;
}

.fade.toast.bg-success.show {
    background: rgb(1, 220, 105);
    background: linear-gradient(90deg, rgba(1, 220, 105, 1) 0%, rgba(10, 143, 91, 1) 0%, rgba(24, 22, 69, 1) 22%, rgba(24, 22, 69, 1) 44%);
}

.statusTxtERROR, .statusTxtError {
    color: #ff0047;
}

.fade.toast.bg-danger.show {
    background: rgb(255, 0, 71);
    background: linear-gradient(90deg, rgba(255, 0, 71, 1) 0%, rgba(255, 0, 71, 1) 0%, rgba(160, 9, 70, 1) 0%, rgba(24, 22, 69, 1) 20%, rgba(24, 22, 69, 1) 44%);
}

.statusTxtINFO {
    color: #1ec0ff;
}

.fade.toast.bg-info.show {
    background: rgb(30, 192, 255);
    background: linear-gradient(90deg, rgba(30, 192, 255, 1) 0%, rgba(30, 192, 255, 1) 0%, rgba(28, 124, 181, 1) 0%, rgba(24, 22, 69, 1) 20%, rgba(24, 22, 69, 1) 44%);
}

.statusTxtWARNING {
    color: #ffcc00;
}

.fade.toast.bg-warning.show {
    background: rgb(255, 204, 0);
    background: linear-gradient(90deg, rgba(255, 204, 0, 1) 0%, rgba(255, 204, 0, 1) 0%, rgba(163, 131, 28, 1) 0%, rgba(24, 22, 69, 1) 20%, rgba(24, 22, 69, 1) 44%);

}

.navyBlueColor {
    color: #181B47 !important;
}

.navyBlueBg {
    background: #181B47 !important;
}

.blueColor {
    color: #2A357C !important;
}

.blueBg {
    background: #2A357C !important;
}

.orangeColor {
    color: #F37C23 !important;
}

.orangeBg {
    background: #F37C23 !important;
}

.greenColor {
    color: #06A45F !important;
}

.greenBg {
    background: #06A45F
}

.btnNavyBlue {
    color: #fff !important;
    background-color: #181B47 !important;
    border-color: #181B47 !important;
}

.btnNavyBlue:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
}

.btnBlue {
    color: #fff !important;
    background-color: #2A357C !important;
    border-color: #2A357C !important;
}

.btnBlue:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
}

.btnOrange {
    color: #fff !important;
    background-color: #F37C23 !important;
    border-color: #F37C23 !important;
}

.btnOrange:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
}

.btnOrange:focus {
    box-shadow: 0 0 0 .25rem rgba(243, 124, 35, 0.72) !important;
}

.btn-outline-orange {
    color: #f37c23 !important;
    border-color: #f37c23 !important;
}

.btn-outline-orange:hover {
    color: #fff !important;
    background-color: #f37c23;
    border-color: #f37c23;
}

.btn-outline-orange:focus {
    box-shadow: 0 0 0 .25rem rgba(243, 124, 35, 0.72) !important;
}

.btn-caccolor:disabled {
    pointer-events: inherit;
}

.preloaderCOLOR1 {
    color: #F37C23 !important;
}

.preloaderCOLOR2 {
    color: #F59248 !important;
}

.preloaderCOLOR3 {
    color: #F7A86C !important;
}

.preloaderCOLOR4 {
    color: #F9BE91 !important;
}

.preloaderCOLOR5 {
    color: #FBD3B6 !important;
}

.preloaderCOLOR6 {
    color: #FDE9DA !important;
}

.preloaderCOLOR7 {
    color: #fff !important;
}

.light-grey {
    color: #716d6d
}
.breakWord{
word-wrap: break-word;
}
.container-box {
    background: #fff;
    border: 1px solid rgba(33, 33, 33, 0.1);
    border-radius: 2px;
    box-shadow: none;
    padding: 20px;
    margin: 20px 0;
}

.toast-body, .toast-header .me-auto {
    color: #fff;
    font-weight: bold;
    text-shadow: 1px 1px 4px #000;
}

footer {
    width: 100%;
}

a {
    color: inherit !important;
    text-decoration: none !important;
    cursor: pointer;
}

.pointer {
    cursor: pointer;
}

.pointer:hover {
    background: aliceblue;
}

.text-uppercase {
    text-transform: uppercase;
}

li .active {
    background: aliceblue;
}

.text-right {
    text-align: right;
}

.float-right {
    float: right;
}

.text-center {
    text-align: center;
}

.w-100 {
    width: 100%;
}

.d-flex {
    display: flex;
}

.img-responsive {
    width: 100%;
    display: inline-block;
}

.cursor-pointer {
    cursor: pointer;
}

.cursor-not-allowed {
    cursor: not-allowed;
}

.pos-relative {
    position: relative;
}

.dn {
    display: none;
}

hr {
    color: #ccc !important;
    opacity: inherit !important;
}

.toast-container.position-absolute.top-0.end-0.p-3 {
    z-index: 99999;
}

.menu-box {
    background: #000;
    color: #fff !important;
    padding: 10px;
}

.responsive-div {
    width: 100%;
    overflow-x: scroll;
}

.img-circle {
    border-radius: 100%;
}

.ck-editor__editable {
    min-height: 200px;
}

.attachlist .file-box {
    float: left;
    margin-bottom: 20px;
}

.attachlist .file {
    border: 1px solid rgba(33, 33, 33, 0.1);
    border-radius: 2px;
    padding: 0;
    background-color: #fff;
    position: relative;
}

.attachlist .file .icon {
    padding: 15px 10px;
    display: table;
    width: 100%;
    text-align: center;
}

.attachlist .file .icon i {
    color: #2879ff;
    display: table-cell;
    font-size: 30px;
    vertical-align: middle;
}

.attachlist .file .file-name {
    padding: 10px;
    background-color: rgba(33, 33, 33, 0.05);
    border-top: 1px solid rgba(33, 33, 33, 0.05);
}

.attachlist .file .icon, .attachlist .file .image {
    height: 150px;
    overflow: hidden;
    background-size: cover;
    background-position: top;
}

.dt-buttons .dt-button {
    background: #0b5ed7;
    color: #fff;
    border-radius: 5px;
    border-color: #0b5ed7;
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: transparent;
    padding: .375rem .75rem;
    font-size: 1rem;
    border-radius: .25rem;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.col-12.row.home-rows.my-4 {
    position: relative;
    bottom: 0;
}

.fade.alert-broadcast.alert.alert-dismissible.show {
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 9999;
}

.alert-broadcast .alert-heading.h4 {
    text-transform: uppercase;
    font-weight: bold;
    border-left: 6px solid;
    padding-left: 10px;
}

.alert-broadcast .broadcast-msg {
    padding-left: 10px;
    text-align: justify;
}

.footerBranding {
    position: absolute;
    bottom: 0;
}

.imgCall {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    box-shadow: 1px 1px 15px brown;
}

.row {
    margin: 0 !important;
}

.table.dataTable.responsive {
    overflow: scroll;
    display: inline-block;
    width: 100%;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    border-color: #000 #000 #fff !important;
}

.col-12.row.home-rows.my-4 img {
    width: 100px;
}

.container-xxl {
    min-height: 400px;
}

.position-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}

.centerBox {
    justify-content: center;
    align-items: center;
    height: 100%;
    display: flex;
}

.rama .bgstall {
    background: #046c63;
}

.blue .bgstall {
    background: #2875cf;
}

.green .bgstall {
    background: #247a31;
}

.purple .bgstall {
    background: #661961;
}

.red .bgstall {
    background: #a62230;
}

.backgroundSize100 {
    background-size: 100% 100% !important;
}

.iframeModal.modal {
    z-index: 9999 !important;
}

.popover-header {
    background: #f37c23 !important;
    color: #fff;
}

.tooltip-inner {
    background-color: #f37c23 !important;
}

.tooltip-arrow::before {
    border-top-color: #f37c23 !important;
}

.tooltip.show {
    opacity: 1 !important;
}

.kQcEQt {
    background: #181b47 !important;
    bottom: 149px !important;
}

.sc-jrQzAO.kAVpBI.rsc-container {
    z-index: 9999;
}

.tagBlockUL {
    list-style: none;
}

.tagBlockUL li {
    width: 20%;
    float: left;
}

.form-check-input.tagcheck {
    margin-right: 10px;
}

.notFound {
    text-transform: uppercase;
}

.profilePage .referenceBlock {
    border: 2px dashed #000;
    padding: 12px;
    background: #fff;
}

.profilePage .referenceBlock h4 {
    font-weight: bold;
}

.profilePage .referenceBlock input {
    border: 2px solid;
    height: 40px;
    border-radius: 20px;
    color: #000;
}

.profilePage .btn.btn-sm.btn-outline-orange {
    margin: 5px;
}

.centerBlockClass {
    justify-content: center;
    align-items: center;
    height: 100%;
}

.objectFitClass {
    object-fit: contain;
}

.HoldBg {
    background: rgb(255, 204, 0);
    background: linear-gradient(90deg, rgba(255, 204, 0, 1) 0%, rgba(0, 0, 0, 1) 0%, rgba(24, 22, 69, 1) 55%);
}

.reset-password .col-md-8 {
    margin: 0 auto;
}

.reset-password .form-control {
    border-radius: 20px;
}

#popover-basic-info {
    max-width: 90%;
}
.form-floating > label{
    left: 5px !important;
}
@-webkit-keyframes mymove {
    50% {
        box-shadow: 0px 0px 6px #f37c23
    }
}
@keyframes mymove {
    50% {
        box-shadow: 0px 0px 6px #f37c23
    }
}

@media only screen and (max-width: 767px) {
    .tagBlockUL li {
        width: 50%;
        float: left;
    }

    .rsc .rsc-container {
        z-index: 99999;
    }

    .rsc .rsc-float-button {
        height: 50px !important;
        width: 50px !important;
        right: 5px !important;
        bottom: 86px !important;
    }
    .react-pdf__Page__canvas{
        width: 100% !important;
    }
    .modal {
        z-index: 9999 !important;
      }
}

@media only screen and (max-height: 450px) and (orientation: landscape) {
    .rsc .rsc-container {
        z-index: 99999;
    }

    .rsc .rsc-float-button {
        height: 60px !important;
        width: 60px !important;
        left: 31px !important;
        bottom: 81px !important;
        background: #181b47;
    }

    .chatbot-view .rsc .rsc-container {
        height: 300px;
    }

    .chatbot-view .rsc .rsc-container {
        height: 300px;
    }

    .chatbot-view .rsc .rsc-content {
        height: 180px;
    }
}
.col-12.login-block2 ,.col-12.login-block2 .login-view{
    padding: 30px;
  }
.login-block2{
    position: relative;
}

  .login-block2 .login-view .leftSide {
    border-radius: 30px 0px 0px 30px;
    position: relative;
    box-shadow: -4px -1px 14px #fff;
  }

  .login-block2 .login-view .rightSide {
    border-radius: 0px 30px 30px 0px;
    background: #fff;
    border: 1px solid #000;    
  }

  .login-form-block2 .form-control{
      border-radius: 20px;
  }
  .login-form-block2 .form-control:focus{
      border-color: #2A357C;
      box-shadow: 0 0 0 1px #2A357C;
  }
  .login-form-block2 .form-label {
    color: #2A357C ;
  }
  .btn-lg.btnBlue.loginBtn.btn.btn-primary, .loginBtn{
    border-radius: 30px !important;
    padding: 10px 50px !important;
  }
  .rightBlock{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}
.rightBlock img{
    object-fit: contain;
}
.login-block2 .footer{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}
.login-block2  .col-12.row.login-view {
  max-width: 1280px;
  margin: 0 auto !important;
}
  .loginRTiltle h2 {
    background: firebrick;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 10px;
    font-weight: bold;
    text-transform: full-width;
    color: #fff;
    text-shadow: 2px 1px 7px #000;
    margin-bottom: 30px;
  }
  .loginRTiltle h1 {
    background: firebrick;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 10px;
    margin-left: 30px;
    font-weight: bold;
    text-transform: full-width;
    text-shadow: 2px 1px 7px #000;
  }
  .loginRbottom.py-4{
    position: absolute;
    bottom: 0;
    right: 13px;
  }
  .loginRbottom h3 {
    font-weight: bold;
    text-shadow: 0px 0px 3px black;
  }
  footer {
    background: #454545;
    color: #fff;
    padding: 10px;
    text-transform: capitalize;
  }
 .login-block2  #example-form .mt-4 input::-webkit-outer-spin-button,
 .login-block2  #example-form .mt-4 input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
 .login-block2  #example-form .mt-4 input[type="number"] {
    -moz-appearance: textfield;
  }
.Mobileloginblock1 .imgLogo.img-responsive {
    width: 200px;
  }
  .Mobileloginblock1 .form-control{
    border-radius: 21px;
    background: transparent;
    color: #fff;
}
.Mobileloginblock1  .form-control:focus{
    border-color: #F37C23;
    box-shadow: 0 0 0 1px #F37C23;
}
.Mobileloginblock1 .form-label {
  color: #F37C23 ;
  font-size: 14px;
}
 .btn-lg.btnOrange.btn.btn-primary{
    border-radius: 30px !important;
    padding: 10px 50px !important;
  }
  .Mobileloginblock1 .footer{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}
footer {
  background: #454545;
  color: #fff;
  padding: 10px;
  text-transform: capitalize;
}
.Mobileloginblock1  #example-form .mt-3 input::-webkit-outer-spin-button,
.Mobileloginblock1 #example-form .mt-3 input::-webkit-inner-spin-button,#example-form .mt-2 input::-webkit-outer-spin-button,
.Mobileloginblock1 #example-form .mt-2 input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.Mobileloginblock1  #example-form .mt-3 input[type="number"], #example-form .mt-2 input[type="number"] {
  -moz-appearance: textfield;
}
.registerViewBlock1 #floatingInput {
    border-radius: 30px;
  }
  .registerDiv1{
      padding: 30px;
  }
  .registerDiv1 .registerViewBlock1 {
    border: 1px solid;
    border-radius: 30px;
  }
  .registerDiv1  .profileimg {
    padding-top: 33px !important;
  }
.col-12.registerBlock2 ,.col-12.registerBlock2 .login-view{
    padding: 30px;
  }
.registerBlock2{
    position: relative;
}

  .registerBlock2 .login-view .leftSide {
    border-radius: 30px 0px 0px 30px;
    position: relative;
    box-shadow: -4px -1px 14px #fff;
  }

  .registerBlock2 .login-view .rightSide {
    border-radius: 0px 30px 30px 0px;
    background: #fff;
    border: 1px solid #000;    
  }
 .registerBlock2 .rightBlock{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}
.registerBlock2 .rightBlock .centerBlock{
    object-fit: contain;
}
.registerBlock2 .profileimg {
  padding-top: 35px !important;
}
footer {
  background: #454545;
  color: #fff;
  padding: 10px;
  text-transform: capitalize;
}
#verifyOTP {
  border-radius: 20px;
  height: 45px;
}
.regtags .dropdown-container {
  border-radius: 30px !important;
}
.mregister .form-control , .mregister .form-select {
    border-radius: 21px;
    background: transparent;
    color: #fff;
  }
  .mregister .form-control:focus, .mregister .form-select:focus {
    border-color: #F37C23;
    box-shadow: 0 0 0 1px #F37C23;
  }
  .mregister label {
    color: #fff;
  }
  .mregister .profileimg {
    padding-top: 35px !important;
  }
  footer {
    background: #454545;
    color: #fff;
    padding: 10px;
    text-transform: capitalize;
  }
  .mregister option {
    color: #000;
  }
  .mregister .btn-lg.btnOrange.btn.btn-primary, .mregister .btn-lg.btnBlue.loginBtn.btn.btn-primary{
    padding: 10px 40px !important;
  }
  .regtags .dropdown-container  {
    border-radius: 30px !important;
  }
  .regtags .dropdown-container label ,  .regtags .dropdown-heading-value, .regtags .dropdown-heading-value span {
    color: #000;
  }
.home-block{
    position: relative;
}
.home-rows {
    display: flex;
    width: 100%;
    margin: 20px 0;
}
.home-auditorium span , .home-helpdesk span , .home-exhibition span {
    background: #000;
    color: #fff;
    padding: 10px;
    border-radius: 20px 0;
}
#vid-skipbutton {
    position: absolute;
    right: 0;
    border-radius: 20px;
    padding: 15px;
    text-align: center;
    z-index: 999999;
  }
.org-dashboard .topbar, .exe-dashboard .topbar {
    margin-top: 20px;
}
.org-dashboard .topbar .col-md-2 .card .topbar .col-md-2 .card {
    box-shadow: 5px 12px 10px #000;
    text-align: center;
}
.org-dashboard .bottombar .header-titles , .exe-dashboard .bottombar .header-titles  {
    padding: 10px;
    border-bottom: 1px dashed;
}
.broadcast-view .bc-row {
    margin-bottom: 20px;
}
.broadcast-view .card-body, .task-view .card-body , .Recentcon-view .card-body , .latest-lead-view .card-body{
    overflow-y: scroll;
    height: 600px;
}
.broadcast-view .col-1 {
    padding-right: 0;
}
.org-dashboard .topbar .card-body {
    padding: 1rem 0rem;
    text-align: center;
}
audio {
    border-radius: 0 0 20px 20px;
}
.roundedCircle50 {
    border-radius: 50%;
    width: 50px;
    height: 50px;
}
.org-dashboard .arrow-sign , .exe-dashboard .arrow-sign{
    position: relative;
    top: 14px;
    margin: 0 10px;
}
.exe-dashboard .name-place {
    min-height: 80px;
}
.dashHeadTitle{
    font-weight: bold;
    padding-left: 10px;
}
@media only screen and (max-width: 767px) {
    .exe-dashboard .col-md-3 {
        margin: 5px auto;
      }
      .leadlist-view .col-2 {
        padding: 0;
      }
}
.exeLiveList-view .col-12.row.ell-row.mb-4 {
    border-bottom: 2px solid;
    padding: 10px;
  }
.col-12.exeregisterBlock2 ,.col-12.exeregisterBlock2 .login-view{
    padding: 30px;
  }
.exeregisterBlock2{
    position: relative;
}
.exeregisterBlock2 #floatingInput {
    border-radius: 30px;
  }
  .exeregisterBlock2 .login-view .leftSide {
    border-radius: 30px 0px 0px 30px;
    position: relative;
    box-shadow: -4px -1px 14px #fff;
  }

  .exeregisterBlock2 .login-view .rightSide {
    border-radius: 0px 30px 30px 0px;
    background: #fff;
    border: 1px solid #000;    
  }
 .exeregisterBlock2 .rightBlock{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}
.exeregisterBlock2 .rightBlock .centerBlock{
    object-fit: contain;
}
.exeregisterBlock2 .profileimg, .exeregisterBlock2 .dropdown-container {
  border-radius: 30px;
}
@media only screen and (max-width: 767px) {
  .col-12.exeregisterBlock2, .col-12.exeregisterBlock2 .login-view {
    padding: 0px;
  }
  .exeregisterBlock2 .login-view .leftSide {
    border-radius: 0;
  }
  .exeregisterBlock2 .login-view .rightSide {
    border-radius: 0;
  }
  .exeregpage footer{
    text-align: center;
  }
}
.webHeader #navbarSupportedContent {
  justify-content: end;
}

.webHeader .header-home-rows.pt-3.flex-column {
  padding: 0;
}

.webHeader .header-home-rows.pt-3.flex-column li {
  margin-bottom: 10px;
  padding-bottom: 12px;
}

.webHeader #navbarSupportedContent .header-home-rows {
  display: flex;
  justify-content: right;
  color: #fff;
  align-items: center
}

.webHeader #navbarSupportedContent .header-home-rows.flex-column li.active {
  padding: 10px;
}

.webHeader #navbarSupportedContent .header-home-rows li {
  width: auto;
  float: left;
  list-style: none;
  padding: 0 20px;
}

.website-image-bg-slider {
  background-position: center !important;
  background-size: cover !important;
  align-items: center;
  text-align: center;
  justify-content: center;
  position: relative;
  display: flex;
}

.website-content-bg {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 250px;
}

.website-content-bg123 h4, .website-content-bg h4 {
  color: #fff;
  font-weight: 800;
  font-size: 36px;
  text-shadow: 0 1px 0 #000, 0 2px 0 #000, 0 3px 0 #000, 0 4px 0 #000, 0 5px 0 #000, 0 6px 1px rgba(0, 0, 0, .1), 0 0 5px rgba(0, 0, 0, .1), 0 1px 3px rgba(0, 0, 0, .3), 0 3px 5px rgba(0, 0, 0, .2), 0 5px 10px rgba(0, 0, 0, .25), 0 10px 10px rgba(0, 0, 0, .2), 0 20px 20px rgba(0, 0, 0, .15);
}

.website-slider-button button {
  padding: 10px 40px;
  color: #fff;
  border-radius: 10px;
  border: 0;
  font-weight: 600;
}

.blue-button {
  background: #36395F;
}

.orange-button {
  background: #EC7C22;
}

.website-logo-header {
  width: 35%;
}

.website-nav-link {
  color: #fff !important;
  text-decoration: none;
}

.nav-link.active1 {
  background: #ec7c22;
  border-radius: 5px;
  color: #fff;
}

.navbar .active {
  border-bottom: 1px solid #ec7c22;
  padding: 7px 20px !important;
}

.services-1 img {
  width: 80px;
}

.type-exhibitors {
  margin-bottom: 50px !important;
}

.expo-services, .about-expo, .type-exhibitors, .stall-package, .have-query, .contact-form, .mission-vision, .webHeader .navbar .container-fluid, #benefits-exhibitors, .faq-section, .vitualAccessPage {
  text-align: center;
  margin: 0 auto !important;
  max-width: 1000px;
}

.bookStall {
  background: #fff;
}

.main-footer .col-md-12.footer {
  text-align: center;
  margin: 0 auto !important;
  max-width: 1200px;
}

.services-1 {
  width: 19%;
  float: left;
  border: 1px solid #ec7c22;
  text-align: center;
  position: relative;
  bottom: 25px;
  background: #fff;
  border-radius: 15px;
  margin: 0 25px;
  box-shadow: -1px 4px 7px #36395f;
}

.services-provide {
  display: inline-block;
  margin: 0 auto;
  text-align: center;
  width: 100%;
}

.services-1 h4 {
  font-size: 18px;
  font-weight: 600;
  color: #36395f;
}

.about-expo {
  margin-top: 50px;
}

.about-expo .col-md-6 p {
  text-align: left;
  color: #36395f;
  font-weight: 400;
  font-size: 18px;
}

.about-expo .col-md-6 h4 {
  color: #36395f;
  font-weight: 600;
  font-size: 28px;
  text-align: left;
}

.about-expo .col-md-12, .type-exhibitors .col-md-12, .contact-form .col-md-12, .mission-vision .col-md-12 {
  display: inline-block;
  margin: 30px 0;
}

.mission-vision h4 {
  font-size: 28px;
  margin: 10px;
  font-weight: 800;
}

.about-expo .col-md-6, .contact-form .col-md-6, .mission-vision .col-md-6 {
  width: 45%;
  float: left;
}

.about-expo .col-md-6 img, .contact-form .col-md-6 img {
  margin: 0 25px;
  width: auto;
  height: auto;
}

.type-exhibitors h4, .Stall-Header h4 {
  color: #36395f;
  font-weight: 600;
  font-size: 36px;
}

.type-4 {
  display: inline-block;
  box-shadow: 1px 3px 5px #36395f;
  border-radius: 15px;
  background: #ec7c22;
  width: 200px;
}

.exhibitor-image {
  border-radius: 15px 15px 35px 35px;
  background: #fff;
}

.type-4 h6 {
  color: #fff;
  font-weight: 600;
  padding: 5px;
  margin: 5px;
}

.package-list {
  text-align: left;
  color: #36395f;
  font-size: 16px;
  padding: 1px;
}

.package-1 {
  display: revert;
  border: 3px solid #ec7c22;
  border-radius: 15px;
  margin: 0 10px;
  width: 310px;
  min-height: 450px;
  position: relative;
  float: left;

}

.package-1 h3 {
  color: #ec7c22;
  font-weight: 500;
  margin: 5px 0;
  border-bottom: 3px solid;
}

.package-price {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  min-height: 100px;
}

.line-throught h4 {
  -webkit-text-decoration-line: line-through;
          text-decoration-line: line-through;
}

.color-orange {
  color: #ec7c22;
  font-size: 28px;
  border-bottom: 1px solid #000;
  padding: 0 20px;
  font-weight: 600;
}

.line-throught h4, .standard-price h4 {
  font-size: 18px;
  font-weight: 500;
}

.standard-price span {
  color: #ec7c22;
  font-weight: 600;
}

.dn {
  display: none;
}

.manage-package {
  display: inline-block;
}

.business-platform .website-image-bg-slider {
  height: 500px;
}

.business-platform h4 {
  font-size: 52px;
  font-weight: 600;
  text-shadow: 1px 1px 3px #000;
  text-transform: uppercase;
  padding-bottom: 20px;
}

.business-platform .orange-button {
  padding: 5px 25px;
  font-size: 24px;
  border: 3px solid #fff;
}

.about-testimonials .myCarousel {
  background: #36395f;
  border: none;
  width: 75%;
}

.about-testimonials h2 {
  text-align: center;
  font-size: 48px;
  font-weight: 600;
  color: #36395f;
}

.about-testimonials .myCarousel h3, .about-testimonials .myCarousel h4, .about-testimonials .myCarousel p {
  color: #fff;
  margin: 10px;
  font-weight: 700;
}

.widget-1.col-md-6, .widget-1.col-md-3, .widget-1.col-md-3 {
  float: left;
  padding: 0 25px;
  color: white;
  height: 180px;
  background: #36395f;
}

.logo-title.my-3 {
  font-size: 48px;
  text-align: center;
  color: #ec7c22;
  font-weight: 600;
}

.links-footer {
  padding: 0 25px;
  float: left;
  text-align: left;
}

.col-md-12.footer {
  text-align: left;
}

.text-center.copyright {
  margin: 0;
  padding: 15px;
  display: flow-root;
}

.social h5 {
  font-size: 16px;
  margin-top: 1.85rem;
}

.social a, .social1 a {
  padding-right: 10px;
}

.bg-dark.text-light {
  background: #ec7c22 !important;
}

.image-bg-slider1 {
  background-position: center !important;
  background-size: cover !important;
  align-items: center;
  display: flex;
  text-align: center;
  justify-content: center;
  height: 400px;
  color: #fff;
}

.query-header h4 {
  font-size: 48px;
  font-weight: 600;
  color: #ec7c22;
}

.query-header h4 span {
  color: #36395f;
}

.contact-form .col-md-6 form {
  margin: 0 auto;
}

form .mb-3.pt-0 input, form .mb-3.pt-0 textarea {
  width: 350px;
  border-radius: 10px;
  border: 1px solid #36395f;
}

.contact-form form textarea {
  min-height: 255px;
}

.mission-vision .col-md-6.mission, .col-md-6.vision {
  background: #36395f;
  max-width: 400px;
  color: #fff;
  padding: 25px;
  margin: 0 25px;
  min-height: 485px;
  border-radius: 40px;
}

.mission p, .vision p {
  text-align: left;
  margin: 15px 0;
}

.type-4.virtual-bharat {
  background: none;
  width: 28%;
  margin: 0 25px;
  min-height: 275px;
  float: left;
}

.virtual-bharat h6 {
  color: #36395f;
  font-size: 14px;
}

.main-footer {
  width: 100%;
  display: grid;
  background: #36395f;
}

.carousel-root {
  width: 64% !important;
  margin: auto !important;
  margin-top: 3% !important;
}

.carousel .slide {
  background: #fff !important;
  color: black;
  height: 100%;
}

.carousel .slide img {
  width: 139px !important;
  border-radius: 50%;
}

.myCarousel {
  background: #fafafa;
  margin-top: -6%;
  width: 54%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 6%;
  padding-bottom: 8%;
  padding-left: 5%;
  padding-right: 5%;
  border: 1px solid #ddd;
  height: 286px;
}

.carousel .control-dots {
  padding-left: 5px !important;
  outline: 0;
  bottom: 5% !important;
}

.myCarousel h3 {
  color: #222;
  font-weight: 100;
  letter-spacing: 0.2px;
  margin-bottom: 4px;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 17px;
}

.myCarousel h4 {
  text-transform: uppercase;
  margin-top: 0;
  padding-top: 0;
  font-weight: 500;
  color: #787878;
  font-size: 14px;
}

.myCarousel p {
  font-weight: 100 !important;
  line-height: 29px !important;
  color: #222;
  font-size: 15px;
  font-family: sans-serif;
  max-height: 67px;
}

.myCarousel p:before {
  content: "“";
  color: #ec7c22;
  font-size: 26px;
  font-family: monospace;
  font-weight: 100;
  padding: 5px;
}

.myCarousel p:after {
  content: "�?";
  color: #ec7c22;
  font-size: 26px;
  font-family: monospace;
  font-weight: 100;
  line-height: 0;
  padding: 5px;
}

.carousel .control-dots .dot {
  box-shadow: none !important;
  background: #fff !important;
  outline: 0;
}

.carousel.carousel-slider .control-arrow {
  background: #36395f !important;
  height: 50px !important;
  position: absolute;
  top: 50% !important;
}

.WB-Privacy, .w-1000 {
  max-width: 1000px;
  margin: 0 auto;

}

.privacy-para1 {
  margin-top: 45px;
  text-align: justify;
}

.privacy-para1 h4 {
  margin: 10px 0;
  font-size: 28px;
  font-weight: 600;
}

.website-slider-button.pt-3 button {
  width: 150px;
}

.slider-button .btn.btnOrange.btn-primary {
  width: 150px;
}

.vitualAccessPage h2 {
  font-size: 62px;
}

.d-block-client-logo {
  width: auto;
  height: 150px;
  padding-bottom: 20px;
  max-width: 150px;
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
  cursor: pointer;
}

.d-block-client-logo:hover {
  -webkit-filter: none;
          filter: none;
}

.advertise-para {
  margin: 0 auto;

}

.advertise-para li::marker {
  color: #ec7c22;
}

.whyus-page .exhibitor-image1 {
  border-radius: 0;
  background: #36395f;
  padding: 25px;
}

.whyus-page .type-4 {
  display: inline-grid;
  width: 280px;
  background: none;
  box-shadow: none;
}

.whyus-page .type-4 h6 {
  padding: 0;
  margin: 5px 0 !important;
font-size: 18px !important;
}

.image-bg-slider1 h6 {
  font-size: 32px;
}

.website-content-bg123 {
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0;
  top: -100px;
  display: inline-grid;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.player-wrapper {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.website-content-bg123 h4 {
  display: flex;
  justify-content: center;
  align-content: center;
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
}

.website-slider-button.pt-3 {
  position: relative;
  top: 40px;
}

@media only screen and (max-width: 934px) {
  .carousel-root {
    outline: 0;
    width: 93% !important;
    margin: auto !important;
  }

  .carousel.carousel-slider .control-arrow {
    display: none !important;
  }

  .myCarousel {
    background: #fafafa;
    margin-top: -9%;
    width: 88%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 8%;
    padding-bottom: 12.5%;
    padding-left: 5%;
    padding-right: 5%;
    border: 1px solid #ddd;
    height: 269px;
  }

  .carousel .slide img {
    width: 24% !important;
    border-radius: 50%;
  }
}

@media only screen and (max-width: 767px) {

  .website-image-bg-slider123 {
    position: relative;
    
  }

  .website-content-bg123 {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: -50px;
    display: flex;
  }
  .services-provide {
    padding: 0 15px;
    margin: 20px 0 0 0;
  }
 

  .services-1 {
    width: 135px;
    margin: 15px;
    position: relative;
    bottom: 0;
    float: none;
    display: inline-block;
  }

  .services-1 h4 {
    font-size: 14px;
  }

  .about-expo,  .stall-package, .exhibitors-logos,  .WB-Privacy {
    margin-top: 3rem !important;
  }

  .about-expo .col-md-12,  .contact-form .col-md-12 {
    margin-top: 0;
  }

  .type-exhibitors .col-md-12, .mission-vision .col-md-12 {
    margin: 0;
  }
  .type-exhibitors.my-5 {
    padding: 2rem 0;
  }
  .header-exhibitors.my-5, .Stall-Header.my-5, .aboutWB .about-expo {
    margin: 0 !important;
  }

  .type-exhibitors .type-4 h6 {
    margin: 0px;
    font-size: 12px;
  }

  .type-exhibitors .type-4.mx-3 {
    width: 140px;
  }

  .about-expo .col-md-6, .contact-form .col-md-6, .mission-vision .col-md-6 {
    width: 100%;
    padding: 0 25px;
  }

  .about-expo .col-md-6 img, .contact-form .col-md-6 img {
    margin: 0;
    width: 100%;
  }

  .type-exhibitors.my-5 .type-4.virtual-bharat {
    width: 150px;
    margin: 10px 8px;
    min-height: 160px;
    float: none;
    display: inline-flex;
    padding:10px;
  }

  .type-exhibitors .exhibitor-image img {
    width: 80px;
  }

  .type-4.mx-3 {
    margin: 10px 0;
  }

  .type-4 h6 {

    padding: 0px;
    margin: 0px;
  }

  .business-platform .website-image-bg-slider {
    height: 400px;
  }

  .business-platform h4 {
    font-size: 24px;
    padding: 0 25px;
  }

  .links-footer {
    padding: 0;
  }

  .links-footer.my-4 {
    margin: 0 !important;
    padding: 0 !important;
  }

  .widget-1.col-md-6, .widget-1.col-md-3, .widget-1.col-md-3 {
    height: auto;
  }

  .widget-1.col-md-6, .widget-1.col-md-3, .widget-1.col-md-3 {
    height: auto;
    margin: 0;
    padding: 0 0px !important;
  }

  .bharat-logo.my-3.py-4 {
    padding: 0 !important;
  }

  .widget-1.col-md-3.py-4 {
    text-align: center;
  }

  .social h5 {
    margin-top: 0;
  }

  .social.my-3 {
    margin: 0 !important;
  }

  .mission-vision .col-md-6.mission, .mission-vision .col-md-6.vision {
    max-width: none;
    margin: 25px 0;
  }

  .mission-vision .row.col-md-12 {
    width: 100% !important;
    padding: 0 15px;
  }

  .type-4.virtual-bharat {
    width: 100%;
    margin: 15px 0;
    min-height: auto;
  }

  .type-exhibitors .row.col-md-12 {
    padding: 0 25px;
  }

  .query-header h4 {
    font-size: 24px;
    padding: 0 15px;
  }

  .mb-3.pt-0 {
    padding: 0 15px !important;
  }

  .contact-form .col-md-6 {
    padding: 0;
  }

  .contact-form .col-md-12 {
    padding: 0;
  }

  form .mb-3.pt-0 input, form .mb-3.pt-0 textarea {
    width: 100%;
  }

  .mission-vision {
    padding: 0 25px
  }

  .image-bg-slider1 {
    height: 200px;
  }

  .WB-Privacy {
    padding: 0 25px;
  }

  .website-content-bg {
    position: absolute;
    top: 35px;
  }

  .website-slider-button.pt-3 button {
    width: 100px;
    padding: 0.50rem 1rem;
  }

  .website-content-bg h4 {
    font-size: 20px;
  }


  .whyus-page .type-4.mx-3 {
    width: 100%;
    padding: 5px 25px;
    margin: 0 !important;
  }

  .image-bg-slider1 h6 {
    font-size: 18px;
  }

  h1.advertise-para {
    font-size: 18px;
    padding: 0 25px;
  }

  ul.advertise-para {
    padding: 0;
  }

  .privacy-para1 {
    text-align: justify !important;
  }

  .website-content-bg123 {
    position: absolute;
    bottom: 0px;
    left: 0;
    right: 0;
    top: -50px;
    display: flex;
  }

  .website-content-bg123 h4 {
    position: absolute;
    left: 0;
    right: 0;
    font-size: 22px;
    padding: 0 50px;
  }

  .vitualAccessPage .vapBlock2 {
    margin-top: 0 !important;
  }

}

.webHeader .navbar {
  z-index: 99999;
  width: 100%;
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .website-content-bg123 {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 250px;
    display: block;
  }

  .website-content-bg123 h4, .website-content-bg h4 {
    font-size: 28px;
  }

  .services-1 {
    margin: 0 20px;
  }

  .services-1 h4 {
    font-size: 12px;
  }

  #benefits-exhibitors, .about-expo, .contact-form, .expo-services, .faq-section, .have-query, .mission-vision, .stall-package, .type-exhibitors, .vitualAccessPage, .webHeader .navbar .container-fluid {
    max-width: 800px;
    padding: 0 15px;
  }

  .about-expo .col-md-6 img, .contact-form .col-md-6 img {
    margin: 0 45px;
    width: 100%;
    height: 100%;
  }

  .bookStall .exhibitors-type-1 .col-md-2 {
    width: 22%;
    margin-right: 20px;
  }

  .website-slider-button.pt-3 {
    position: relative;
    top: 35px;
  }

  .col-md-6.vision, .mission-vision .col-md-6.mission {
    max-width: none;
    padding: 20px;
    margin: 0 18px;
    min-height: 560px;

  }

  .type-4.virtual-bharat .exhibitor-image img {
    width: 150px;
  }

  .type-4.virtual-bharat {
    width: 29%;
    margin: 0 15px;
    min-height: 225px;

  }

  .vitualAccessPage .website-slider-button.pt-3 {
    position: relative;
    top: 0;
  }

  .WB-Privacy.my-4 {
    padding: 0 20px;
  }
}
.pricingPage .pricing {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    margin: 0 auto;
}

.pricingPage .pricing-item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    text-align: center;
    flex: 0 1 330px;
}

.pricingPage .pricing-action {
    color: inherit;
    border: none;
    background: none;
    cursor: pointer;
}

.pricingPage .pricing-action:focus {
    outline: none;
}

.pricingPage .pricing-feature-list {
    text-align: left;
}

.pricingPage .pricing-palden .pricing-item {
    font-family: 'Open Sans', sans-serif;
    cursor: default;
    color: #84697c;
    background: #fff;
    box-shadow: 0 0 10px rgba(46, 59, 125, 0.23);
    border-radius: 20px 20px 10px 10px;
    margin: 1em;
}

@media screen and (min-width: 66.25em) {
    .pricingPage .pricing-palden .pricing-item {
        margin: 1em -0.5em;
    }

    .pricingPage .pricing-palden .pricing__item--featured {
        margin: 0;
        z-index: 10;
        box-shadow: 0 0 20px rgba(46, 59, 125, 0.23);
    }
}

.pricingPage .pricing-palden .pricing-deco {
    border-radius: 10px 10px 0 0;
    background: linear-gradient(135deg, #36395f, #36395f);
    padding: 3em 0 8em;
    position: relative;
}

.pricingPage .pricing-palden .pricing-deco-img {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 130px;
}

.pricingPage .pricing-palden .pricing-title {
    font-size: 0.75em;
    margin: 0;
    text-transform: uppercase;
    letter-spacing: 5px;
    color: #fff;
}

.pricingPage .pricing-palden .deco-layer {
    transition: -webkit-transform 0.5s;
    transition: transform 0.5s;
    transition: transform 0.5s, -webkit-transform 0.5s;
}

.pricingPage .pricing-palden .pricing-item:hover .deco-layer--1 {
    -webkit-transform: translate3d(15px, 0, 0);
    transform: translate3d(15px, 0, 0);
}

.pricingPage .pricing-palden .pricing-item:hover .deco-layer--2 {
    -webkit-transform: translate3d(-15px, 0, 0);
    transform: translate3d(-15px, 0, 0);
}

.pricingPage .pricing-palden .icon {
    font-size: 2.5em;
}

.pricingPage .pricing-palden .pricing-price {
    font-size: 42px;
    font-weight: bold;
    padding: 0;
    color: #fff;
    margin: 0 0 0.25em 0;
    line-height: 0.75;
}

.pricingPage .header .package-price {
    position: relative;
    bottom: 35px;
}

.pricingPage .header section {
    padding:  0;
}

.pricingPage .package-title {
    padding: 3rem 0 2rem;
    text-shadow: 0 1px 0 #000, 0 2px 0 #000, 0 3px 0 #000, 0 4px 0 #000, 0 5px 0 #000, 0 6px 1px rgba(0, 0, 0, .1), 0 0 5px rgba(0, 0, 0, .1), 0 1px 3px rgba(0, 0, 0, .3), 0 3px 5px rgba(0, 0, 0, .2), 0 5px 10px rgba(0, 0, 0, .25), 0 10px 10px rgba(0, 0, 0, .2), 0 20px 20px rgba(0, 0, 0, .15);
    color: #fff;
    font-size: 62px;
    font-weight: 600;
  }

.pricingPage .pricing-palden .pricing-currency {
    font-size: 0.15em;
    vertical-align: top;
}

.pricingPage .pricing-palden .pricing-period {
    font-size: 0.15em;
    padding: 0 0 0 0.5em;
    font-style: italic;
}

.pricingPage .pricing-palden .pricing__sentence {
    font-weight: bold;
    margin: 0 0 1em 0;
    padding: 0 0 0.5em;
}

.pricingPage .pricing-palden .pricing-feature-list {
    margin: 0;
    text-align: left;
    position: relative;
    bottom: 60px;
}

.pricingPage .pricing-palden .pricing-feature {
    padding: 1px;
    list-style: disclosure-closed;
}

.pricingPage .pricing-palden .pricing-action {
    font-weight: bold;
    margin: auto 3em 2em 3em;
    padding: 1em 2em;
    color: #fff;
    border-radius: 30px;
    background: linear-gradient(0deg, #36395f, #36395f);
    transition: background-color 0.3s;
}

.pricingPage .pricing-palden .pricing-action:hover,
.pricingPage .pricing-palden .pricing-action:focus {
    background: linear-gradient(135deg, #ec7c22, #ec7c22);
}

.pricingPage .type-exhibitors {
    margin-bottom: 50px;
}

.pricingPage .pricing-palden .pricing-item--featured .pricing-deco {
    padding: 5em 0 8.885em 0;
}

.pricingPage .header {
    position: relative;
    text-align: center;
    background-image: radial-gradient(circle at 39% 47%, rgba(107, 107, 107, 0.08) 0%, rgba(107, 107, 107, 0.08) 33.333%, rgba(72, 72, 72, 0.08) 33.333%, rgba(72, 72, 72, 0.08) 66.666%, rgba(36, 36, 36, 0.08) 66.666%, rgba(36, 36, 36, 0.08) 99.999%), radial-gradient(circle at 53% 74%, rgba(182, 182, 182, 0.08) 0%, rgba(182, 182, 182, 0.08) 33.333%, rgba(202, 202, 202, 0.08) 33.333%, rgba(202, 202, 202, 0.08) 66.666%, rgba(236, 124, 34, 0) 66.666%, rgb(54, 57, 95) 99.999%), radial-gradient(circle at 14% 98%, rgb(236, 124, 34) 0%, rgb(54, 57, 95) 33.333%, rgb(236, 124, 34) 33.333%, rgb(54, 57, 95) 66.666%, rgb(236, 124, 34) 66.666%, rgb(54, 57, 95) 99.999%), linear-gradient(45deg, rgb(236, 124, 34), rgb(54, 57, 95));
    ;
    color: white;
}

.pricingPage .inner-header {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
}

.pricingPage .flex {
    /*Flexbox for containers*/
    justify-content: center;
    align-items: center;
    text-align: center;
}

.pricingPage .waves {
    position: relative;
    width: 100%;
    height: 150vh;
    margin-bottom: -7px;
    /*Fix for safari gap*/
    min-height: 100px;
    max-height: 250px;
}

/* Animation */

.pricingPage .parallax>use {
    -webkit-animation: move-forever 25s cubic-bezier(.55, .5, .45, .5) infinite;
            animation: move-forever 25s cubic-bezier(.55, .5, .45, .5) infinite;
}

.pricingPage .parallax>use:nth-child(1) {
    -webkit-animation-delay: -2s;
            animation-delay: -2s;
    -webkit-animation-duration: 7s;
            animation-duration: 7s;
}

.pricingPage .parallax>use:nth-child(2) {
    -webkit-animation-delay: -3s;
            animation-delay: -3s;
    -webkit-animation-duration: 10s;
            animation-duration: 10s;
}

.pricingPage .parallax>use:nth-child(3) {
    -webkit-animation-delay: -4s;
            animation-delay: -4s;
    -webkit-animation-duration: 13s;
            animation-duration: 13s;
}

.pricingPage .parallax>use:nth-child(4) {
    -webkit-animation-delay: -5s;
            animation-delay: -5s;
    -webkit-animation-duration: 20s;
            animation-duration: 20s;
}

@-webkit-keyframes move-forever {
    0% {
        -webkit-transform: translate3d(-90px, 0, 0);
                transform: translate3d(-90px, 0, 0);
    }

    100% {
        -webkit-transform: translate3d(85px, 0, 0);
                transform: translate3d(85px, 0, 0);
    }
}

@keyframes move-forever {
    0% {
        -webkit-transform: translate3d(-90px, 0, 0);
                transform: translate3d(-90px, 0, 0);
    }

    100% {
        -webkit-transform: translate3d(85px, 0, 0);
                transform: translate3d(85px, 0, 0);
    }
}

/* Book A Stall Css */
.bookStall #benefits-exhibitors h4, .faq-section h4 {
    color: #36395f;
    font-size: 42px;
    font-weight: 600;
}

.bookStall #benefits-exhibitors .my-5 p {
    color: #36395f;
    font-size: 20px;
    font-weight: 500;
}

.bookStall .exhibitors-type-1 img {
    width: 150px;
    padding: 20px;
}

.bookStall .exhibitors-type-1 .col-md-2 {
    float: left;
    background: #36395f;
    border-radius: 15px;
}

.bookStall .exhibitors-type-1 .col-md-10 {
    text-align: left;
    margin: 0 auto;
    max-width: 550px;
}

.bookStall #benefits-exhibitors h5 {
    margin-top: 25px;
    color: #ec7c22;
    font-size: 24px;
    font-weight: 500;
}

.bookStall .exhibitors-type-1 p {
    color: #36395f;
    font-size: 18px;
}

.bookStall .accordion-item {
    margin: 15px;
}

.bookStall .main-faq {
    background: #d7d7d754;
}

.bookStall #benefits-exhibitors {
    margin-bottom: 3rem;
    margin-top: 3rem;
}

.bookStall .accordion-body {
    text-align: left;
}

.bookStall .accordion-button:focus {
    box-shadow: none !important;
}

.bookStall .accordion-button:not(.collapsed) {
    color: #fff !important;
    background-color: #36395f !important;
}

@media only screen and (max-width: 767px) {
    .bookStall  #benefits-exhibitors h4, .faq-section h4 {
        font-size: 24px;
      }
    .bookStall .exhibitors-type-1 img {
        width: 80px;
        padding: 5px;
    }

    .bookStall #benefits-exhibitors h5 {
        margin-top: 0;
        font-size: 16px;
        position: relative;
        left: 15px;
    }
    .bookStall .exhibitors-type-1 p {
        font-size: 12px;
        position: relative;
        padding: 0 15px;
        display: table;
      }
      .pricingPage .package-title{
        font-size: 48px;
      }
}
