.pricingPage .pricing {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: center;
    justify-content: center;
    width: 100%;
    margin: 0 auto;
}

.pricingPage .pricing-item {
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: stretch;
    align-items: stretch;
    text-align: center;
    -webkit-flex: 0 1 330px;
    flex: 0 1 330px;
}

.pricingPage .pricing-action {
    color: inherit;
    border: none;
    background: none;
    cursor: pointer;
}

.pricingPage .pricing-action:focus {
    outline: none;
}

.pricingPage .pricing-feature-list {
    text-align: left;
}

.pricingPage .pricing-palden .pricing-item {
    font-family: 'Open Sans', sans-serif;
    cursor: default;
    color: #84697c;
    background: #fff;
    box-shadow: 0 0 10px rgba(46, 59, 125, 0.23);
    border-radius: 20px 20px 10px 10px;
    margin: 1em;
}

@media screen and (min-width: 66.25em) {
    .pricingPage .pricing-palden .pricing-item {
        margin: 1em -0.5em;
    }

    .pricingPage .pricing-palden .pricing__item--featured {
        margin: 0;
        z-index: 10;
        box-shadow: 0 0 20px rgba(46, 59, 125, 0.23);
    }
}

.pricingPage .pricing-palden .pricing-deco {
    border-radius: 10px 10px 0 0;
    background: linear-gradient(135deg, #36395f, #36395f);
    padding: 3em 0 8em;
    position: relative;
}

.pricingPage .pricing-palden .pricing-deco-img {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 130px;
}

.pricingPage .pricing-palden .pricing-title {
    font-size: 0.75em;
    margin: 0;
    text-transform: uppercase;
    letter-spacing: 5px;
    color: #fff;
}

.pricingPage .pricing-palden .deco-layer {
    -webkit-transition: -webkit-transform 0.5s;
    transition: transform 0.5s;
}

.pricingPage .pricing-palden .pricing-item:hover .deco-layer--1 {
    -webkit-transform: translate3d(15px, 0, 0);
    transform: translate3d(15px, 0, 0);
}

.pricingPage .pricing-palden .pricing-item:hover .deco-layer--2 {
    -webkit-transform: translate3d(-15px, 0, 0);
    transform: translate3d(-15px, 0, 0);
}

.pricingPage .pricing-palden .icon {
    font-size: 2.5em;
}

.pricingPage .pricing-palden .pricing-price {
    font-size: 42px;
    font-weight: bold;
    padding: 0;
    color: #fff;
    margin: 0 0 0.25em 0;
    line-height: 0.75;
}

.pricingPage .header .package-price {
    position: relative;
    bottom: 35px;
}

.pricingPage .header section {
    padding:  0;
}

.pricingPage .package-title {
    padding: 3rem 0 2rem;
    text-shadow: 0 1px 0 #000, 0 2px 0 #000, 0 3px 0 #000, 0 4px 0 #000, 0 5px 0 #000, 0 6px 1px rgba(0, 0, 0, .1), 0 0 5px rgba(0, 0, 0, .1), 0 1px 3px rgba(0, 0, 0, .3), 0 3px 5px rgba(0, 0, 0, .2), 0 5px 10px rgba(0, 0, 0, .25), 0 10px 10px rgba(0, 0, 0, .2), 0 20px 20px rgba(0, 0, 0, .15);
    color: #fff;
    font-size: 62px;
    font-weight: 600;
  }

.pricingPage .pricing-palden .pricing-currency {
    font-size: 0.15em;
    vertical-align: top;
}

.pricingPage .pricing-palden .pricing-period {
    font-size: 0.15em;
    padding: 0 0 0 0.5em;
    font-style: italic;
}

.pricingPage .pricing-palden .pricing__sentence {
    font-weight: bold;
    margin: 0 0 1em 0;
    padding: 0 0 0.5em;
}

.pricingPage .pricing-palden .pricing-feature-list {
    margin: 0;
    text-align: left;
    position: relative;
    bottom: 60px;
}

.pricingPage .pricing-palden .pricing-feature {
    padding: 1px;
    list-style: disclosure-closed;
}

.pricingPage .pricing-palden .pricing-action {
    font-weight: bold;
    margin: auto 3em 2em 3em;
    padding: 1em 2em;
    color: #fff;
    border-radius: 30px;
    background: linear-gradient(0deg, #36395f, #36395f);
    -webkit-transition: background-color 0.3s;
    transition: background-color 0.3s;
}

.pricingPage .pricing-palden .pricing-action:hover,
.pricingPage .pricing-palden .pricing-action:focus {
    background: linear-gradient(135deg, #ec7c22, #ec7c22);
}

.pricingPage .type-exhibitors {
    margin-bottom: 50px;
}

.pricingPage .pricing-palden .pricing-item--featured .pricing-deco {
    padding: 5em 0 8.885em 0;
}

@import url(//fonts.googleapis.com/css?family=Lato:300:400);

.pricingPage .header {
    position: relative;
    text-align: center;
    background-image: radial-gradient(circle at 39% 47%, rgba(107, 107, 107, 0.08) 0%, rgba(107, 107, 107, 0.08) 33.333%, rgba(72, 72, 72, 0.08) 33.333%, rgba(72, 72, 72, 0.08) 66.666%, rgba(36, 36, 36, 0.08) 66.666%, rgba(36, 36, 36, 0.08) 99.999%), radial-gradient(circle at 53% 74%, rgba(182, 182, 182, 0.08) 0%, rgba(182, 182, 182, 0.08) 33.333%, rgba(202, 202, 202, 0.08) 33.333%, rgba(202, 202, 202, 0.08) 66.666%, rgba(236, 124, 34, 0) 66.666%, rgb(54, 57, 95) 99.999%), radial-gradient(circle at 14% 98%, rgb(236, 124, 34) 0%, rgb(54, 57, 95) 33.333%, rgb(236, 124, 34) 33.333%, rgb(54, 57, 95) 66.666%, rgb(236, 124, 34) 66.666%, rgb(54, 57, 95) 99.999%), linear-gradient(45deg, rgb(236, 124, 34), rgb(54, 57, 95));
    ;
    color: white;
}

.pricingPage .inner-header {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
}

.pricingPage .flex {
    /*Flexbox for containers*/
    justify-content: center;
    align-items: center;
    text-align: center;
}

.pricingPage .waves {
    position: relative;
    width: 100%;
    height: 150vh;
    margin-bottom: -7px;
    /*Fix for safari gap*/
    min-height: 100px;
    max-height: 250px;
}

/* Animation */

.pricingPage .parallax>use {
    animation: move-forever 25s cubic-bezier(.55, .5, .45, .5) infinite;
}

.pricingPage .parallax>use:nth-child(1) {
    animation-delay: -2s;
    animation-duration: 7s;
}

.pricingPage .parallax>use:nth-child(2) {
    animation-delay: -3s;
    animation-duration: 10s;
}

.pricingPage .parallax>use:nth-child(3) {
    animation-delay: -4s;
    animation-duration: 13s;
}

.pricingPage .parallax>use:nth-child(4) {
    animation-delay: -5s;
    animation-duration: 20s;
}

@keyframes move-forever {
    0% {
        transform: translate3d(-90px, 0, 0);
    }

    100% {
        transform: translate3d(85px, 0, 0);
    }
}

/* Book A Stall Css */
.bookStall #benefits-exhibitors h4, .faq-section h4 {
    color: #36395f;
    font-size: 42px;
    font-weight: 600;
}

.bookStall #benefits-exhibitors .my-5 p {
    color: #36395f;
    font-size: 20px;
    font-weight: 500;
}

.bookStall .exhibitors-type-1 img {
    width: 150px;
    padding: 20px;
}

.bookStall .exhibitors-type-1 .col-md-2 {
    float: left;
    background: #36395f;
    border-radius: 15px;
}

.bookStall .exhibitors-type-1 .col-md-10 {
    text-align: left;
    margin: 0 auto;
    max-width: 550px;
}

.bookStall #benefits-exhibitors h5 {
    margin-top: 25px;
    color: #ec7c22;
    font-size: 24px;
    font-weight: 500;
}

.bookStall .exhibitors-type-1 p {
    color: #36395f;
    font-size: 18px;
}

.bookStall .accordion-item {
    margin: 15px;
}

.bookStall .main-faq {
    background: #d7d7d754;
}

.bookStall #benefits-exhibitors {
    margin-bottom: 3rem;
    margin-top: 3rem;
}

.bookStall .accordion-body {
    text-align: left;
}

.bookStall .accordion-button:focus {
    box-shadow: none !important;
}

.bookStall .accordion-button:not(.collapsed) {
    color: #fff !important;
    background-color: #36395f !important;
}

@media only screen and (max-width: 767px) {
    .bookStall  #benefits-exhibitors h4, .faq-section h4 {
        font-size: 24px;
      }
    .bookStall .exhibitors-type-1 img {
        width: 80px;
        padding: 5px;
    }

    .bookStall #benefits-exhibitors h5 {
        margin-top: 0;
        font-size: 16px;
        position: relative;
        left: 15px;
    }
    .bookStall .exhibitors-type-1 p {
        font-size: 12px;
        position: relative;
        padding: 0 15px;
        display: table;
      }
      .pricingPage .package-title{
        font-size: 48px;
      }
}