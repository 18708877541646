.webHeader #navbarSupportedContent {
  justify-content: end;
}

.webHeader .header-home-rows.pt-3.flex-column {
  padding: 0;
}

.webHeader .header-home-rows.pt-3.flex-column li {
  margin-bottom: 10px;
  padding-bottom: 12px;
}

.webHeader #navbarSupportedContent .header-home-rows {
  display: flex;
  justify-content: right;
  color: #fff;
  align-items: center
}

.webHeader #navbarSupportedContent .header-home-rows.flex-column li.active {
  padding: 10px;
}

.webHeader #navbarSupportedContent .header-home-rows li {
  width: auto;
  float: left;
  list-style: none;
  padding: 0 20px;
}

.website-image-bg-slider {
  background-position: center !important;
  background-size: cover !important;
  align-items: center;
  text-align: center;
  justify-content: center;
  position: relative;
  display: flex;
}

.website-content-bg {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 250px;
}

.website-content-bg123 h4, .website-content-bg h4 {
  color: #fff;
  font-weight: 800;
  font-size: 36px;
  text-shadow: 0 1px 0 #000, 0 2px 0 #000, 0 3px 0 #000, 0 4px 0 #000, 0 5px 0 #000, 0 6px 1px rgba(0, 0, 0, .1), 0 0 5px rgba(0, 0, 0, .1), 0 1px 3px rgba(0, 0, 0, .3), 0 3px 5px rgba(0, 0, 0, .2), 0 5px 10px rgba(0, 0, 0, .25), 0 10px 10px rgba(0, 0, 0, .2), 0 20px 20px rgba(0, 0, 0, .15);
}

.website-slider-button button {
  padding: 10px 40px;
  color: #fff;
  border-radius: 10px;
  border: 0;
  font-weight: 600;
}

.blue-button {
  background: #36395F;
}

.orange-button {
  background: #EC7C22;
}

.website-logo-header {
  width: 35%;
}

.website-nav-link {
  color: #fff !important;
  text-decoration: none;
}

.nav-link.active1 {
  background: #ec7c22;
  border-radius: 5px;
  color: #fff;
}

.navbar .active {
  border-bottom: 1px solid #ec7c22;
  padding: 7px 20px !important;
}

.services-1 img {
  width: 80px;
}

.type-exhibitors {
  margin-bottom: 50px !important;
}

.expo-services, .about-expo, .type-exhibitors, .stall-package, .have-query, .contact-form, .mission-vision, .webHeader .navbar .container-fluid, #benefits-exhibitors, .faq-section, .vitualAccessPage {
  text-align: center;
  margin: 0 auto !important;
  max-width: 1000px;
}

.bookStall {
  background: #fff;
}

.main-footer .col-md-12.footer {
  text-align: center;
  margin: 0 auto !important;
  max-width: 1200px;
}

.services-1 {
  width: 19%;
  float: left;
  border: 1px solid #ec7c22;
  text-align: center;
  position: relative;
  bottom: 25px;
  background: #fff;
  border-radius: 15px;
  margin: 0 25px;
  box-shadow: -1px 4px 7px #36395f;
}

.services-provide {
  display: inline-block;
  margin: 0 auto;
  text-align: center;
  width: 100%;
}

.services-1 h4 {
  font-size: 18px;
  font-weight: 600;
  color: #36395f;
}

.about-expo {
  margin-top: 50px;
}

.about-expo .col-md-6 p {
  text-align: left;
  color: #36395f;
  font-weight: 400;
  font-size: 18px;
}

.about-expo .col-md-6 h4 {
  color: #36395f;
  font-weight: 600;
  font-size: 28px;
  text-align: left;
}

.about-expo .col-md-12, .type-exhibitors .col-md-12, .contact-form .col-md-12, .mission-vision .col-md-12 {
  display: inline-block;
  margin: 30px 0;
}

.mission-vision h4 {
  font-size: 28px;
  margin: 10px;
  font-weight: 800;
}

.about-expo .col-md-6, .contact-form .col-md-6, .mission-vision .col-md-6 {
  width: 45%;
  float: left;
}

.about-expo .col-md-6 img, .contact-form .col-md-6 img {
  margin: 0 25px;
  width: auto;
  height: auto;
}

.type-exhibitors h4, .Stall-Header h4 {
  color: #36395f;
  font-weight: 600;
  font-size: 36px;
}

.type-4 {
  display: inline-block;
  box-shadow: 1px 3px 5px #36395f;
  border-radius: 15px;
  background: #ec7c22;
  width: 200px;
}

.exhibitor-image {
  border-radius: 15px 15px 35px 35px;
  background: #fff;
}

.type-4 h6 {
  color: #fff;
  font-weight: 600;
  padding: 5px;
  margin: 5px;
}

.package-list {
  text-align: left;
  color: #36395f;
  font-size: 16px;
  padding: 1px;
}

.package-1 {
  display: revert;
  border: 3px solid #ec7c22;
  border-radius: 15px;
  margin: 0 10px;
  width: 310px;
  min-height: 450px;
  position: relative;
  float: left;

}

.package-1 h3 {
  color: #ec7c22;
  font-weight: 500;
  margin: 5px 0;
  border-bottom: 3px solid;
}

.package-price {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  min-height: 100px;
}

.line-throught h4 {
  text-decoration-line: line-through;
}

.color-orange {
  color: #ec7c22;
  font-size: 28px;
  border-bottom: 1px solid #000;
  padding: 0 20px;
  font-weight: 600;
}

.line-throught h4, .standard-price h4 {
  font-size: 18px;
  font-weight: 500;
}

.standard-price span {
  color: #ec7c22;
  font-weight: 600;
}

.dn {
  display: none;
}

.manage-package {
  display: inline-block;
}

.business-platform .website-image-bg-slider {
  height: 500px;
}

.business-platform h4 {
  font-size: 52px;
  font-weight: 600;
  text-shadow: 1px 1px 3px #000;
  text-transform: uppercase;
  padding-bottom: 20px;
}

.business-platform .orange-button {
  padding: 5px 25px;
  font-size: 24px;
  border: 3px solid #fff;
}

.about-testimonials .myCarousel {
  background: #36395f;
  border: none;
  width: 75%;
}

.about-testimonials h2 {
  text-align: center;
  font-size: 48px;
  font-weight: 600;
  color: #36395f;
}

.about-testimonials .myCarousel h3, .about-testimonials .myCarousel h4, .about-testimonials .myCarousel p {
  color: #fff;
  margin: 10px;
  font-weight: 700;
}

.widget-1.col-md-6, .widget-1.col-md-3, .widget-1.col-md-3 {
  float: left;
  padding: 0 25px;
  color: white;
  height: 180px;
  background: #36395f;
}

.logo-title.my-3 {
  font-size: 48px;
  text-align: center;
  color: #ec7c22;
  font-weight: 600;
}

.links-footer {
  padding: 0 25px;
  float: left;
  text-align: left;
}

.col-md-12.footer {
  text-align: left;
}

.text-center.copyright {
  margin: 0;
  padding: 15px;
  display: flow-root;
}

.social h5 {
  font-size: 16px;
  margin-top: 1.85rem;
}

.social a, .social1 a {
  padding-right: 10px;
}

.bg-dark.text-light {
  background: #ec7c22 !important;
}

.image-bg-slider1 {
  background-position: center !important;
  background-size: cover !important;
  align-items: center;
  display: flex;
  text-align: center;
  justify-content: center;
  height: 400px;
  color: #fff;
}

.query-header h4 {
  font-size: 48px;
  font-weight: 600;
  color: #ec7c22;
}

.query-header h4 span {
  color: #36395f;
}

.contact-form .col-md-6 form {
  margin: 0 auto;
}

form .mb-3.pt-0 input, form .mb-3.pt-0 textarea {
  width: 350px;
  border-radius: 10px;
  border: 1px solid #36395f;
}

.contact-form form textarea {
  min-height: 255px;
}

.mission-vision .col-md-6.mission, .col-md-6.vision {
  background: #36395f;
  max-width: 400px;
  color: #fff;
  padding: 25px;
  margin: 0 25px;
  min-height: 485px;
  border-radius: 40px;
}

.mission p, .vision p {
  text-align: left;
  margin: 15px 0;
}

.type-4.virtual-bharat {
  background: none;
  width: 28%;
  margin: 0 25px;
  min-height: 275px;
  float: left;
}

.virtual-bharat h6 {
  color: #36395f;
  font-size: 14px;
}

.main-footer {
  width: 100%;
  display: grid;
  background: #36395f;
}

.carousel-root {
  width: 64% !important;
  margin: auto !important;
  margin-top: 3% !important;
}

.carousel .slide {
  background: #fff !important;
  color: black;
  height: 100%;
}

.carousel .slide img {
  width: 139px !important;
  border-radius: 50%;
}

.myCarousel {
  background: #fafafa;
  margin-top: -6%;
  width: 54%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 6%;
  padding-bottom: 8%;
  padding-left: 5%;
  padding-right: 5%;
  border: 1px solid #ddd;
  height: 286px;
}

.carousel .control-dots {
  padding-left: 5px !important;
  outline: 0;
  bottom: 5% !important;
}

.myCarousel h3 {
  color: #222;
  font-weight: 100;
  letter-spacing: 0.2px;
  margin-bottom: 4px;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 17px;
}

.myCarousel h4 {
  text-transform: uppercase;
  margin-top: 0;
  padding-top: 0;
  font-weight: 500;
  color: #787878;
  font-size: 14px;
}

.myCarousel p {
  font-weight: 100 !important;
  line-height: 29px !important;
  color: #222;
  font-size: 15px;
  font-family: sans-serif;
  max-height: 67px;
}

.myCarousel p:before {
  content: "“";
  color: #ec7c22;
  font-size: 26px;
  font-family: monospace;
  font-weight: 100;
  padding: 5px;
}

.myCarousel p:after {
  content: "�?";
  color: #ec7c22;
  font-size: 26px;
  font-family: monospace;
  font-weight: 100;
  line-height: 0;
  padding: 5px;
}

.carousel .control-dots .dot {
  box-shadow: none !important;
  background: #fff !important;
  outline: 0;
}

.carousel.carousel-slider .control-arrow {
  background: #36395f !important;
  height: 50px !important;
  position: absolute;
  top: 50% !important;
}

.WB-Privacy, .w-1000 {
  max-width: 1000px;
  margin: 0 auto;

}

.privacy-para1 {
  margin-top: 45px;
  text-align: justify;
}

.privacy-para1 h4 {
  margin: 10px 0;
  font-size: 28px;
  font-weight: 600;
}

.website-slider-button.pt-3 button {
  width: 150px;
}

.slider-button .btn.btnOrange.btn-primary {
  width: 150px;
}

.vitualAccessPage h2 {
  font-size: 62px;
}

.d-block-client-logo {
  width: auto;
  height: 150px;
  padding-bottom: 20px;
  max-width: 150px;
  filter: grayscale(100%);
  cursor: pointer;
}

.d-block-client-logo:hover {
  filter: none;
}

.advertise-para {
  margin: 0 auto;

}

.advertise-para li::marker {
  color: #ec7c22;
}

.whyus-page .exhibitor-image1 {
  border-radius: 0;
  background: #36395f;
  padding: 25px;
}

.whyus-page .type-4 {
  display: inline-grid;
  width: 280px;
  background: none;
  box-shadow: none;
}

.whyus-page .type-4 h6 {
  padding: 0;
  margin: 5px 0 !important;
font-size: 18px !important;
}

.image-bg-slider1 h6 {
  font-size: 32px;
}

.website-content-bg123 {
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0;
  top: -100px;
  display: inline-grid;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.player-wrapper {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.website-content-bg123 h4 {
  display: flex;
  justify-content: center;
  align-content: center;
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
}

.website-slider-button.pt-3 {
  position: relative;
  top: 40px;
}

@media only screen and (max-width: 934px) {
  .carousel-root {
    outline: 0;
    width: 93% !important;
    margin: auto !important;
  }

  .carousel.carousel-slider .control-arrow {
    display: none !important;
  }

  .myCarousel {
    background: #fafafa;
    margin-top: -9%;
    width: 88%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 8%;
    padding-bottom: 12.5%;
    padding-left: 5%;
    padding-right: 5%;
    border: 1px solid #ddd;
    height: 269px;
  }

  .carousel .slide img {
    width: 24% !important;
    border-radius: 50%;
  }
}

@media only screen and (max-width: 767px) {

  .website-image-bg-slider123 {
    position: relative;
    
  }

  .website-content-bg123 {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: -50px;
    display: flex;
  }
  .services-provide {
    padding: 0 15px;
    margin: 20px 0 0 0;
  }
 

  .services-1 {
    width: 135px;
    margin: 15px;
    position: relative;
    bottom: 0;
    float: none;
    display: inline-block;
  }

  .services-1 h4 {
    font-size: 14px;
  }

  .about-expo,  .stall-package, .exhibitors-logos,  .WB-Privacy {
    margin-top: 3rem !important;
  }

  .about-expo .col-md-12,  .contact-form .col-md-12 {
    margin-top: 0;
  }

  .type-exhibitors .col-md-12, .mission-vision .col-md-12 {
    margin: 0;
  }
  .type-exhibitors.my-5 {
    padding: 2rem 0;
  }
  .header-exhibitors.my-5, .Stall-Header.my-5, .aboutWB .about-expo {
    margin: 0 !important;
  }

  .type-exhibitors .type-4 h6 {
    margin: 0px;
    font-size: 12px;
  }

  .type-exhibitors .type-4.mx-3 {
    width: 140px;
  }

  .about-expo .col-md-6, .contact-form .col-md-6, .mission-vision .col-md-6 {
    width: 100%;
    padding: 0 25px;
  }

  .about-expo .col-md-6 img, .contact-form .col-md-6 img {
    margin: 0;
    width: 100%;
  }

  .type-exhibitors.my-5 .type-4.virtual-bharat {
    width: 150px;
    margin: 10px 8px;
    min-height: 160px;
    float: none;
    display: inline-flex;
    padding:10px;
  }

  .type-exhibitors .exhibitor-image img {
    width: 80px;
  }

  .type-4.mx-3 {
    margin: 10px 0;
  }

  .type-4 h6 {

    padding: 0px;
    margin: 0px;
  }

  .business-platform .website-image-bg-slider {
    height: 400px;
  }

  .business-platform h4 {
    font-size: 24px;
    padding: 0 25px;
  }

  .links-footer {
    padding: 0;
  }

  .links-footer.my-4 {
    margin: 0 !important;
    padding: 0 !important;
  }

  .widget-1.col-md-6, .widget-1.col-md-3, .widget-1.col-md-3 {
    height: auto;
  }

  .widget-1.col-md-6, .widget-1.col-md-3, .widget-1.col-md-3 {
    height: auto;
    margin: 0;
    padding: 0 0px !important;
  }

  .bharat-logo.my-3.py-4 {
    padding: 0 !important;
  }

  .widget-1.col-md-3.py-4 {
    text-align: center;
  }

  .social h5 {
    margin-top: 0;
  }

  .social.my-3 {
    margin: 0 !important;
  }

  .mission-vision .col-md-6.mission, .mission-vision .col-md-6.vision {
    max-width: none;
    margin: 25px 0;
  }

  .mission-vision .row.col-md-12 {
    width: 100% !important;
    padding: 0 15px;
  }

  .type-4.virtual-bharat {
    width: 100%;
    margin: 15px 0;
    min-height: auto;
  }

  .type-exhibitors .row.col-md-12 {
    padding: 0 25px;
  }

  .query-header h4 {
    font-size: 24px;
    padding: 0 15px;
  }

  .mb-3.pt-0 {
    padding: 0 15px !important;
  }

  .contact-form .col-md-6 {
    padding: 0;
  }

  .contact-form .col-md-12 {
    padding: 0;
  }

  form .mb-3.pt-0 input, form .mb-3.pt-0 textarea {
    width: 100%;
  }

  .mission-vision {
    padding: 0 25px
  }

  .image-bg-slider1 {
    height: 200px;
  }

  .WB-Privacy {
    padding: 0 25px;
  }

  .website-content-bg {
    position: absolute;
    top: 35px;
  }

  .website-slider-button.pt-3 button {
    width: 100px;
    padding: 0.50rem 1rem;
  }

  .website-content-bg h4 {
    font-size: 20px;
  }


  .whyus-page .type-4.mx-3 {
    width: 100%;
    padding: 5px 25px;
    margin: 0 !important;
  }

  .image-bg-slider1 h6 {
    font-size: 18px;
  }

  h1.advertise-para {
    font-size: 18px;
    padding: 0 25px;
  }

  ul.advertise-para {
    padding: 0;
  }

  .privacy-para1 {
    text-align: justify !important;
  }

  .website-content-bg123 {
    position: absolute;
    bottom: 0px;
    left: 0;
    right: 0;
    top: -50px;
    display: flex;
  }

  .website-content-bg123 h4 {
    position: absolute;
    left: 0;
    right: 0;
    font-size: 22px;
    padding: 0 50px;
  }

  .vitualAccessPage .vapBlock2 {
    margin-top: 0 !important;
  }

}

.webHeader .navbar {
  z-index: 99999;
  width: 100%;
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .website-content-bg123 {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 250px;
    display: block;
  }

  .website-content-bg123 h4, .website-content-bg h4 {
    font-size: 28px;
  }

  .services-1 {
    margin: 0 20px;
  }

  .services-1 h4 {
    font-size: 12px;
  }

  #benefits-exhibitors, .about-expo, .contact-form, .expo-services, .faq-section, .have-query, .mission-vision, .stall-package, .type-exhibitors, .vitualAccessPage, .webHeader .navbar .container-fluid {
    max-width: 800px;
    padding: 0 15px;
  }

  .about-expo .col-md-6 img, .contact-form .col-md-6 img {
    margin: 0 45px;
    width: 100%;
    height: 100%;
  }

  .bookStall .exhibitors-type-1 .col-md-2 {
    width: 22%;
    margin-right: 20px;
  }

  .website-slider-button.pt-3 {
    position: relative;
    top: 35px;
  }

  .col-md-6.vision, .mission-vision .col-md-6.mission {
    max-width: none;
    padding: 20px;
    margin: 0 18px;
    min-height: 560px;

  }

  .type-4.virtual-bharat .exhibitor-image img {
    width: 150px;
  }

  .type-4.virtual-bharat {
    width: 29%;
    margin: 0 15px;
    min-height: 225px;

  }

  .vitualAccessPage .website-slider-button.pt-3 {
    position: relative;
    top: 0;
  }

  .WB-Privacy.my-4 {
    padding: 0 20px;
  }
}