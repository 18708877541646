@font-face {
    font-family: "PlusJakartaSans";
    src: url('https://bharatexpo.com/PlusJakartaSans.ttf');
}

body {
    background: #f7f7f7 !important;
    color: #000;
    font-family: 'PlusJakartaSans' !important;
}

.statusTxtSUCCESS, .statusTxtSuccess {
    color: #01dc69;
}

.fade.toast.bg-success.show {
    background: rgb(1, 220, 105);
    background: linear-gradient(90deg, rgba(1, 220, 105, 1) 0%, rgba(10, 143, 91, 1) 0%, rgba(24, 22, 69, 1) 22%, rgba(24, 22, 69, 1) 44%);
}

.statusTxtERROR, .statusTxtError {
    color: #ff0047;
}

.fade.toast.bg-danger.show {
    background: rgb(255, 0, 71);
    background: linear-gradient(90deg, rgba(255, 0, 71, 1) 0%, rgba(255, 0, 71, 1) 0%, rgba(160, 9, 70, 1) 0%, rgba(24, 22, 69, 1) 20%, rgba(24, 22, 69, 1) 44%);
}

.statusTxtINFO {
    color: #1ec0ff;
}

.fade.toast.bg-info.show {
    background: rgb(30, 192, 255);
    background: linear-gradient(90deg, rgba(30, 192, 255, 1) 0%, rgba(30, 192, 255, 1) 0%, rgba(28, 124, 181, 1) 0%, rgba(24, 22, 69, 1) 20%, rgba(24, 22, 69, 1) 44%);
}

.statusTxtWARNING {
    color: #ffcc00;
}

.fade.toast.bg-warning.show {
    background: rgb(255, 204, 0);
    background: linear-gradient(90deg, rgba(255, 204, 0, 1) 0%, rgba(255, 204, 0, 1) 0%, rgba(163, 131, 28, 1) 0%, rgba(24, 22, 69, 1) 20%, rgba(24, 22, 69, 1) 44%);

}

.navyBlueColor {
    color: #181B47 !important;
}

.navyBlueBg {
    background: #181B47 !important;
}

.blueColor {
    color: #2A357C !important;
}

.blueBg {
    background: #2A357C !important;
}

.orangeColor {
    color: #F37C23 !important;
}

.orangeBg {
    background: #F37C23 !important;
}

.greenColor {
    color: #06A45F !important;
}

.greenBg {
    background: #06A45F
}

.btnNavyBlue {
    color: #fff !important;
    background-color: #181B47 !important;
    border-color: #181B47 !important;
}

.btnNavyBlue:hover {
    transform: scale(1.1);
}

.btnBlue {
    color: #fff !important;
    background-color: #2A357C !important;
    border-color: #2A357C !important;
}

.btnBlue:hover {
    transform: scale(1.1);
}

.btnOrange {
    color: #fff !important;
    background-color: #F37C23 !important;
    border-color: #F37C23 !important;
}

.btnOrange:hover {
    transform: scale(1.1);
}

.btnOrange:focus {
    box-shadow: 0 0 0 .25rem rgba(243, 124, 35, 0.72) !important;
}

.btn-outline-orange {
    color: #f37c23 !important;
    border-color: #f37c23 !important;
}

.btn-outline-orange:hover {
    color: #fff !important;
    background-color: #f37c23;
    border-color: #f37c23;
}

.btn-outline-orange:focus {
    box-shadow: 0 0 0 .25rem rgba(243, 124, 35, 0.72) !important;
}

.btn-caccolor:disabled {
    pointer-events: inherit;
}

.preloaderCOLOR1 {
    color: #F37C23 !important;
}

.preloaderCOLOR2 {
    color: #F59248 !important;
}

.preloaderCOLOR3 {
    color: #F7A86C !important;
}

.preloaderCOLOR4 {
    color: #F9BE91 !important;
}

.preloaderCOLOR5 {
    color: #FBD3B6 !important;
}

.preloaderCOLOR6 {
    color: #FDE9DA !important;
}

.preloaderCOLOR7 {
    color: #fff !important;
}

.light-grey {
    color: #716d6d
}
.breakWord{
word-wrap: break-word;
}
.container-box {
    background: #fff;
    border: 1px solid rgba(33, 33, 33, 0.1);
    border-radius: 2px;
    box-shadow: none;
    padding: 20px;
    margin: 20px 0;
}

.toast-body, .toast-header .me-auto {
    color: #fff;
    font-weight: bold;
    text-shadow: 1px 1px 4px #000;
}

footer {
    width: 100%;
}

a {
    color: inherit !important;
    text-decoration: none !important;
    cursor: pointer;
}

.pointer {
    cursor: pointer;
}

.pointer:hover {
    background: aliceblue;
}

.text-uppercase {
    text-transform: uppercase;
}

li .active {
    background: aliceblue;
}

.text-right {
    text-align: right;
}

.float-right {
    float: right;
}

.text-center {
    text-align: center;
}

.w-100 {
    width: 100%;
}

.d-flex {
    display: flex;
}

.img-responsive {
    width: 100%;
    display: inline-block;
}

.cursor-pointer {
    cursor: pointer;
}

.cursor-not-allowed {
    cursor: not-allowed;
}

.pos-relative {
    position: relative;
}

.dn {
    display: none;
}

hr {
    color: #ccc !important;
    opacity: inherit !important;
}

.toast-container.position-absolute.top-0.end-0.p-3 {
    z-index: 99999;
}

.menu-box {
    background: #000;
    color: #fff !important;
    padding: 10px;
}

.responsive-div {
    width: 100%;
    overflow-x: scroll;
}

.img-circle {
    border-radius: 100%;
}

.ck-editor__editable {
    min-height: 200px;
}

.attachlist .file-box {
    float: left;
    margin-bottom: 20px;
}

.attachlist .file {
    border: 1px solid rgba(33, 33, 33, 0.1);
    border-radius: 2px;
    padding: 0;
    background-color: #fff;
    position: relative;
}

.attachlist .file .icon {
    padding: 15px 10px;
    display: table;
    width: 100%;
    text-align: center;
}

.attachlist .file .icon i {
    color: #2879ff;
    display: table-cell;
    font-size: 30px;
    vertical-align: middle;
}

.attachlist .file .file-name {
    padding: 10px;
    background-color: rgba(33, 33, 33, 0.05);
    border-top: 1px solid rgba(33, 33, 33, 0.05);
}

.attachlist .file .icon, .attachlist .file .image {
    height: 150px;
    overflow: hidden;
    background-size: cover;
    background-position: top;
}

.dt-buttons .dt-button {
    background: #0b5ed7;
    color: #fff;
    border-radius: 5px;
    border-color: #0b5ed7;
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    user-select: none;
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: transparent;
    padding: .375rem .75rem;
    font-size: 1rem;
    border-radius: .25rem;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.col-12.row.home-rows.my-4 {
    position: relative;
    bottom: 0;
}

.fade.alert-broadcast.alert.alert-dismissible.show {
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 9999;
}

.alert-broadcast .alert-heading.h4 {
    text-transform: uppercase;
    font-weight: bold;
    border-left: 6px solid;
    padding-left: 10px;
}

.alert-broadcast .broadcast-msg {
    padding-left: 10px;
    text-align: justify;
}

.footerBranding {
    position: absolute;
    bottom: 0;
}

.imgCall {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    box-shadow: 1px 1px 15px brown;
}

.row {
    margin: 0 !important;
}

.table.dataTable.responsive {
    overflow: scroll;
    display: inline-block;
    width: 100%;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    border-color: #000 #000 #fff !important;
}

.col-12.row.home-rows.my-4 img {
    width: 100px;
}

.container-xxl {
    min-height: 400px;
}

.position-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}

.centerBox {
    justify-content: center;
    align-items: center;
    height: 100%;
    display: flex;
}

.rama .bgstall {
    background: #046c63;
}

.blue .bgstall {
    background: #2875cf;
}

.green .bgstall {
    background: #247a31;
}

.purple .bgstall {
    background: #661961;
}

.red .bgstall {
    background: #a62230;
}

.backgroundSize100 {
    background-size: 100% 100% !important;
}

.iframeModal.modal {
    z-index: 9999 !important;
}

.popover-header {
    background: #f37c23 !important;
    color: #fff;
}

.tooltip-inner {
    background-color: #f37c23 !important;
}

.tooltip-arrow::before {
    border-top-color: #f37c23 !important;
}

.tooltip.show {
    opacity: 1 !important;
}

.kQcEQt {
    background: #181b47 !important;
    bottom: 149px !important;
}

.sc-jrQzAO.kAVpBI.rsc-container {
    z-index: 9999;
}

.tagBlockUL {
    list-style: none;
}

.tagBlockUL li {
    width: 20%;
    float: left;
}

.form-check-input.tagcheck {
    margin-right: 10px;
}

.notFound {
    text-transform: uppercase;
}

.profilePage .referenceBlock {
    border: 2px dashed #000;
    padding: 12px;
    background: #fff;
}

.profilePage .referenceBlock h4 {
    font-weight: bold;
}

.profilePage .referenceBlock input {
    border: 2px solid;
    height: 40px;
    border-radius: 20px;
    color: #000;
}

.profilePage .btn.btn-sm.btn-outline-orange {
    margin: 5px;
}

.centerBlockClass {
    justify-content: center;
    align-items: center;
    height: 100%;
}

.objectFitClass {
    object-fit: contain;
}

.HoldBg {
    background: rgb(255, 204, 0);
    background: linear-gradient(90deg, rgba(255, 204, 0, 1) 0%, rgba(0, 0, 0, 1) 0%, rgba(24, 22, 69, 1) 55%);
}

.reset-password .col-md-8 {
    margin: 0 auto;
}

.reset-password .form-control {
    border-radius: 20px;
}

#popover-basic-info {
    max-width: 90%;
}
.form-floating > label{
    left: 5px !important;
}
@keyframes mymove {
    50% {
        box-shadow: 0px 0px 6px #f37c23
    }
}

@media only screen and (max-width: 767px) {
    .tagBlockUL li {
        width: 50%;
        float: left;
    }

    .rsc .rsc-container {
        z-index: 99999;
    }

    .rsc .rsc-float-button {
        height: 50px !important;
        width: 50px !important;
        right: 5px !important;
        bottom: 86px !important;
    }
    .react-pdf__Page__canvas{
        width: 100% !important;
    }
    .modal {
        z-index: 9999 !important;
      }
}

@media only screen and (max-height: 450px) and (orientation: landscape) {
    .rsc .rsc-container {
        z-index: 99999;
    }

    .rsc .rsc-float-button {
        height: 60px !important;
        width: 60px !important;
        left: 31px !important;
        bottom: 81px !important;
        background: #181b47;
    }

    .chatbot-view .rsc .rsc-container {
        height: 300px;
    }

    .chatbot-view .rsc .rsc-container {
        height: 300px;
    }

    .chatbot-view .rsc .rsc-content {
        height: 180px;
    }
}