.org-dashboard .topbar, .exe-dashboard .topbar {
    margin-top: 20px;
}
.org-dashboard .topbar .col-md-2 .card .topbar .col-md-2 .card {
    box-shadow: 5px 12px 10px #000;
    text-align: center;
}
.org-dashboard .bottombar .header-titles , .exe-dashboard .bottombar .header-titles  {
    padding: 10px;
    border-bottom: 1px dashed;
}
.broadcast-view .bc-row {
    margin-bottom: 20px;
}
.broadcast-view .card-body, .task-view .card-body , .Recentcon-view .card-body , .latest-lead-view .card-body{
    overflow-y: scroll;
    height: 600px;
}
.broadcast-view .col-1 {
    padding-right: 0;
}
.org-dashboard .topbar .card-body {
    padding: 1rem 0rem;
    text-align: center;
}
audio {
    border-radius: 0 0 20px 20px;
}
.roundedCircle50 {
    border-radius: 50%;
    width: 50px;
    height: 50px;
}
.org-dashboard .arrow-sign , .exe-dashboard .arrow-sign{
    position: relative;
    top: 14px;
    margin: 0 10px;
}
.exe-dashboard .name-place {
    min-height: 80px;
}
.dashHeadTitle{
    font-weight: bold;
    padding-left: 10px;
}
@media only screen and (max-width: 767px) {
    .exe-dashboard .col-md-3 {
        margin: 5px auto;
      }
      .leadlist-view .col-2 {
        padding: 0;
      }
}