.col-12.exeregisterBlock2 ,.col-12.exeregisterBlock2 .login-view{
    padding: 30px;
  }
.exeregisterBlock2{
    position: relative;
}
.exeregisterBlock2 #floatingInput {
    border-radius: 30px;
  }
  .exeregisterBlock2 .login-view .leftSide {
    border-radius: 30px 0px 0px 30px;
    position: relative;
    box-shadow: -4px -1px 14px #fff;
  }

  .exeregisterBlock2 .login-view .rightSide {
    border-radius: 0px 30px 30px 0px;
    background: #fff;
    border: 1px solid #000;    
  }
 .exeregisterBlock2 .rightBlock{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}
.exeregisterBlock2 .rightBlock .centerBlock{
    object-fit: contain;
}
.exeregisterBlock2 .profileimg, .exeregisterBlock2 .dropdown-container {
  border-radius: 30px;
}
@media only screen and (max-width: 767px) {
  .col-12.exeregisterBlock2, .col-12.exeregisterBlock2 .login-view {
    padding: 0px;
  }
  .exeregisterBlock2 .login-view .leftSide {
    border-radius: 0;
  }
  .exeregisterBlock2 .login-view .rightSide {
    border-radius: 0;
  }
  .exeregpage footer{
    text-align: center;
  }
}