.registerViewBlock1 #floatingInput {
    border-radius: 30px;
  }
  .registerDiv1{
      padding: 30px;
  }
  .registerDiv1 .registerViewBlock1 {
    border: 1px solid;
    border-radius: 30px;
  }
  .registerDiv1  .profileimg {
    padding-top: 33px !important;
  }