.mregister .form-control , .mregister .form-select {
    border-radius: 21px;
    background: transparent;
    color: #fff;
  }
  .mregister .form-control:focus, .mregister .form-select:focus {
    border-color: #F37C23;
    box-shadow: 0 0 0 1px #F37C23;
  }
  .mregister label {
    color: #fff;
  }
  .mregister .profileimg {
    padding-top: 35px !important;
  }
  footer {
    background: #454545;
    color: #fff;
    padding: 10px;
    text-transform: capitalize;
  }
  .mregister option {
    color: #000;
  }
  .mregister .btn-lg.btnOrange.btn.btn-primary, .mregister .btn-lg.btnBlue.loginBtn.btn.btn-primary{
    padding: 10px 40px !important;
  }
  .regtags .dropdown-container  {
    border-radius: 30px !important;
  }
  .regtags .dropdown-container label ,  .regtags .dropdown-heading-value, .regtags .dropdown-heading-value span {
    color: #000;
  }